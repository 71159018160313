import React from "react"
import { Link } from "gatsby"

const Article = (props) => {

    const { frontmatter } = props.article
    const slug = props.article.fields.slug
    
    return (
        <div className="article-card my-2">
            <div className="article-text">
                <Link to={slug}>
                    <h2 className="article-title">{frontmatter.title}</h2>
                </Link>
            </div>
        </div>
    )
}

export default Article