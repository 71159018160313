import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Book from "../components/reading"
import Article from "../components/article"
import Social from "../components/social"

const IndexPage = (props) =>  {

  const articles = props.data.articles.nodes


  return (
    <Layout>
      <SEO title="Home | The Flying Dev" />
      <section className="flex items-center flex-col  pt-24 pb-12">

      <h1 className="mb-5">Hiya! I'm FabioRosado <span role="img" aria-label="waving hand">👋</span></h1>
      <p className="mb-5">I'm a flight attendant and a self-taught developer. TheFlyingDev came to be as a joke in a Makers community, when another member said that since I was flying and coding I'm the flying dev. I liked the nickname and started using it when I created my twitch channel.</p>
      <p className="mb-5">I'm passionate about open source, love building new projects live on twitch and I am also the creator and host of the <a href="https://landingintech.com">Landing in Tech</a> podcast.</p>
      
      <h2>You can find me here</h2>
      <Social />

      </section>
      <section className="flex justify-center items-center flex-col py-12">
        <h2 className="mb-5 green-plane underline red">Currently Reading</h2>
        <Book book={props.data.book.nodes[0]} />
      </section>
      <section className="flex flex-col justify-center py-12">
        <h2 className="mb-5 green-plane underline red">Latest posts</h2>
        {articles.map(article => <Article key={article.frontmatter.title} article={article} />)}
      </section>

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    book: allMdx(filter: {frontmatter: {progress: {ne: "100%"}}, fields: {slug: {regex: "/books/"}}}) {
      nodes {
      frontmatter {
          author
          progress
          title
          amazon
          image {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
      fields {
        slug
      }
     }
  }

  articles: allMdx(
    filter: {fields: {slug: {regex: "/digital-garden/"}}}, 
    sort: {fields: [frontmatter___date], order: DESC},
    limit: 4) {
    nodes {
      frontmatter {
        title
        category
        excerpt
      }
      fields {
        slug
      }
    }
  }
}
`